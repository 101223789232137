import { render, staticRenderFns } from "./sendTable.vue?vue&type=template&id=0df4e174&scoped=true&"
import script from "./sendTable.vue?vue&type=script&lang=js&"
export * from "./sendTable.vue?vue&type=script&lang=js&"
import style0 from "./sendTable.vue?vue&type=style&index=0&id=0df4e174&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0df4e174",
  null
  
)

export default component.exports